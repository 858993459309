import { defineStore } from 'pinia'

/**
 * Use this store in every view to menage global loading indicators
 */
export const useViewLoadingStore = defineStore('viewLoading', {
  state: (): { loadingKey: symbol | null } => ({
    loadingKey: Symbol('viewLoadingStoreKey'),
  }),
  actions: {
    startLoading() {
      this.loadingKey = Symbol('viewLoadingStoreKey')
    },
    finishLoading() {
      this.loadingKey = null
    },
  },
  getters: {
    isLoading(): boolean {
      return this.loadingKey !== null
    },
  },
})
